<template>
  <div class="d-flex align-items-center justify-content-between mt-50 mb-50 w-100 flex-wrap">
    <div class="d-flex align-items-center justify-content-start m-50">
      <slot name="image"/>
      <slot name="additionnal-text"/>
      <div>
        <span v-if="name" class="font-weight-bold font-medium-4">
          {{ name }}
        </span>
      </div>
    </div>
    <div class="d-flex flex-wrap align-items-md-stretch justify-content-end m-50">
      <b-button
          v-if="state.state === 'PA'"
          class="m-25"
          variant="primary"
          size="sm"
          @click="plantAcquiredPrompt = true">
        J'ai le plant
      </b-button>
      <b-button
          class="m-25"
          variant="primary"
          size="sm"
          @click="modifyDone">
        {{ text }}
        <span v-if="!inSeason" class="text-sm italic"> <br> (Hors saison) </span>
      </b-button>
      <VGardenPlantHistory
          v-if="state.state === 'PL'"
          :onlyVGPlantId="onlyVGPlantId"
          :plantId="plantId"
          :plantName="name"
          :vGarden="vGarden" class="ml-1"/>
    </div>

    <b-modal
        v-model="validateTaskPopUp"
        id="validateModal"
        centered
        hide-footer
        size="xl"
        title="Confirmation"
        @close="setNotDone">

      <b-form-group class="px-1" label="Fait le :">
        <flat-pickr v-model="dateDone" :config="configDateTimePicker" class="form-control"/>
      </b-form-group>

      <div v-if="state.state === 'PA'" class="px-50">
        <p class="mb-1">
          Conseil(s) :
          <br>
          - Semez à une profondeur de 3 fois le diamètre de la graine.
          <span v-if="plantSowing === 'CO' || plantSowing === 'EO'">
          <br>
            - Semez <strong>{{ name }}</strong> en {{ plantSowing === 'CO' ? 'godet' : 'terre' }}<br>
          </span>
        </p>
        <div class="d-flex flex-wrap justify-content-around">
          <b-button
              class="mb-1"
              variant="primary"
              size="sm"
              @click="setDone('SO')">
            J'ai semé en godet
          </b-button>

          <b-button
              class="mb-1"
              variant="primary"
              size="sm"
              @click="setDone('PL')">
            J'ai semé en terre
          </b-button>
        </div>

        <div class="d-flex flex-wrap justify-content-around">
          <b-button
              class="mb-1"
              variant="flat-secondary"
              size="sm"
              @click="sowingHelpPopUp = true">
            En savoir plus
          </b-button>
        </div>
      </div>

      <div v-if="state.state === 'SO' || state.state === 'AC'" class="d-flex flex-wrap justify-content-around px-50">
        <b-button
            class="mb-2"
            variant="primary"
            size="sm"
            @click="setDone('PL')">
          J'ai repiqué ma plante
        </b-button>
        <b-list-group flush>
          <b-list-group-item class="font-small-3">
            Si la plante craint le gel, plantez après les dernières gelées.
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Idéalement au moment de planter le contenant est rempli de racines.
          </b-list-group-item>
        </b-list-group>
      </div>

      <div v-if="state.state === 'PL'" class="d-flex flex-wrap justify-content-around w-100">
        <VGardenPlantsFeedBack
            ref="vGFeedBack"
            :key="feedBackCanvasKey"
            :onlyVGPlantId="onlyVGPlantId"
            :plantId="plantId"
            :modalActive="validateTaskPopUp"
            :vGarden="vGarden"
            class="w-100 mb-1"
            v-on:newFeedback="newFeedback"/>
        <b-button
            class="mb-50"
            variant="primary"
            @click="setDone('HA')">
          Valider la récolte
        </b-button>
      </div>

      <div class="d-flex flex-wrap justify-content-around">
        <b-button
            variant="outline-warning"
            @click="setNotDone">
          Annuler
        </b-button>
      </div>

      <b-modal
          v-model="sowingHelpPopUp"
          centered
          class="info-popup"
          hide-footer
          title="Les semis">
        <h3 class="mb-25">Semer en godet</h3>
        <b-list-group flush>
          <b-list-group-item class="font-small-3">
            Précocité grâce au démarrage sous abris
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Plants à l'abri des adventices, des bio-agresseurs et des aléas météorologiques
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Surveillance facile
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Choix des plants en meilleur santé
          </b-list-group-item>
        </b-list-group>
        <h2 class="m-0">VS</h2>
        <h3 class="mb-25">Semer en terre</h3>
        <b-list-group flush>
          <b-list-group-item class="font-small-3">
            Gain de temps
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Meilleur développement racinaire de la plante
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Nécessite peu de materiel
          </b-list-group-item>
          <b-list-group-item class="font-small-3">
            Moins de travail
          </b-list-group-item>
        </b-list-group>
      </b-modal>
    </b-modal>

    <b-modal
        v-model="plantAcquiredPrompt"
        cancel-title="Annuler"
        centered
        ok-title="J'ai le plant"
        title="Confirmation"
        @ok="setDone('AC')">

      <b-form-group description="Vous avez acquis le plant ?"
          label="Fait le :">
        <flat-pickr v-model="dateDone" :config="configDateTimePicker" class="form-control"/>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import VGardenPlantsFeedBack from '@/components/vgarden/tasks/VGardenPlantsFeedBack.vue'
import VGardenPlantHistory from '@/components/vgarden/tasks/VGardenPlantHistory.vue'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {BButton, BFormGroup, BListGroup, BListGroupItem, BModal} from "bootstrap-vue";

export default {
  name: 'PlantTask',
  components: {
    BButton,
    BModal,
    BListGroup,
    BListGroupItem,
    FlatPickr,
    BFormGroup,
    VGardenPlantsFeedBack,
    VGardenPlantHistory
  },
  props: {
    vGarden: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary'
    },
    plantId: {
      type: Number,
      default: null
    },
    onlyVGPlantId: {
      type: Number,
      default: null
    },
    inSeason: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      taskDone: false,
      dateDone: null,
      oldDate: '',
      validateTaskPopUp: false,
      // Date time settings
      configDateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        defaultDate: new Date(),
        maxDate: new Date()
      },
      feedBackCanvasKey: 1,
      feedBack: {},
      quantity: 0,
      sowingHelpPopUp: false,
      plantAcquiredPrompt: false
    }
  },
  mounted() {
    this.configDateTimePicker.minDate = new Date(this.state.state === 'PA' ?
        null : this.state.date)
  },
  computed: {
    plantSowing() {
      let plant = this.vGarden.vgarden_plants.find(vgplant => vgplant.garden_plant.plant.id === this.plantId)
      return plant ? plant.garden_plant.plant.jaya_infos.sowing_method : 'BO'
    }
  },
  methods: {
    setDone(info) {
      this.validateTaskPopUp = false
      let eventPlayload = {type: this.state.state, date: this.dateDone, info: info, plantId: this.plantId}
      if (info === 'HA') {
        eventPlayload.feedBack = this.feedBack.plantResults ? this.feedBack : null
        this.$emit('harvest', eventPlayload)
      } else {
        this.$emit('taskValidated', eventPlayload)
      }
    },
    setNotDone() {
      this.dateDone = null
      this.validateTaskPopUp = false
    },
    modifyDone() {
      if (!this.taskDone) {
        this.validateTaskPopUp = true
        this.feedBackCanvasKey = this.feedBackCanvasKey + 1
      } else {
        this.oldDate = this.dateDone
      }
    },
    newFeedback(val) {
      this.feedBack = val
    }
  },
  watch: {
    state() {
      this.taskDone = false
    }
  }
}
</script>
