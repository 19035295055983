<template>
  <div>
    <div class="d-flex align-items-center cursor-pointer h-100" @click="openPlantHistoryPopUp()">
      <feather-icon
          icon="ClockIcon"
          size="24"/>
    </div>


    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="plantHistoryPopUp"
          :title="'Récoltes pour ' + plantName"
          centered
          hide-footer>

        <b-form-select
            v-model="daysPast"
            :options="daysPastOptions"
            class="mb-1"/>

        <div v-if="harvests.totalMass > 0 || harvests.totalNumber > 0">
          <strong class="mr-2">Total:</strong>
          <span v-if="harvests.totalMass > 0"> {{ harvests.totalMass }} kg </span>
          <span v-if="harvests.totalMass > 0 && harvests.totalNumber > 0"> et </span>
          <span v-if="harvests.totalNumber > 0"> {{ harvests.totalNumber }} organe(s) </span>

          <div
              v-for="(harvest, index) of harvests.results"
              :key="index"
              :class="harvest.id===editId ? 'text-primary' : ''">
          <span v-if="harvest.date && (harvest.mass > 0 || harvest.number > 0)" class="text-italic mr-1"> - {{
              dateParser(harvest.date)
            }} : </span>
            <span v-if="harvest.mass > 0"> {{ harvest.mass }} kg </span>
            <span v-if="harvest.mass > 0 && harvest.number"> et </span>
            <span v-if="harvest.number > 0" class="ml-25">{{ harvest.number }} organe(s) </span>
            <feather-icon
                :icon="qualityParser(harvest.quality)"
                size="20"/>

            <span class="cursor-pointer" @click="editHarvest(harvest.id)">
              <feather-icon
                  :icon="editId === harvest.id ? 'XIcon' : 'Edit2Icon'"
                  class="cursor-pointer text-primary ml-2"
                  size="16"></feather-icon>
            </span>

            <div v-if="editId === harvest.id" class="my-50 text-dark">
              <b-col class="align-items-center p-0" cols="12">
                <validation-provider v-slot="{errors}" :rules="{min_value:0, numeric:null}">
                  <b-input-group class="p-0">
                    <b-input-group-prepend is-text>
                      Masse (kg)
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="mass"
                        min="0"
                        step="0.1"
                        type="number"/>
                  </b-input-group>
                  <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
                </validation-provider>
              </b-col>
              <b-col class="align-items-center p-0" cols="12">
                <validation-provider v-slot="{errors}" :rules="{min_value:1, integer:null}">
                  <b-input-group class="p-0">
                    <b-input-group-prepend is-text>
                      Organe recoltable
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="quantity"
                        min="0"
                        step="1"
                        type="number"/>
                  </b-input-group>
                  <b-form-text class=" text-center text-danger font-small-3">{{ errors[0] }}</b-form-text>
                </validation-provider>
              </b-col>
              <div class="d-flex justify-content-center mt-1">
                <b-button :diabled="invalid" variant="primary" @click="updateHarvest">Valider</b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-danger text-italic">
          Pas de récoltes durant cette période.
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {BButton, BCol, BFormInput, BFormSelect, BModal, BFormText, BInputGroupPrepend, BInputGroup} from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {integer, min_value, numeric} from 'vee-validate/dist/rules'

extend('min_value', {...min_value, message: "Vous ne pouvez pas avoir récolté moins de {min} ! :)"});
extend('numeric', {...numeric, message: "Le champ doit être numérique!"});
extend('integer', {...integer, message: "Le champ doit être un nombre entier!"});

export default {
  components: {
    BCol,
    BButton,
    BModal,
    BFormSelect,
    BFormInput,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    vGarden: {
      type: Object
    },
    onlyVGPlantId: {
      type: Number,
      default: null
    },
    plantId: {
      type: Number,
      default: -1
    },
    plantName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      plantHistoryPopUp: false,
      daysPast: 7,
      daysPastOptions: [
        {value: 7, text: 'Dernière semaine'},
        {value: 30, text: 'Dernier mois'},
        {value: 65, text: 'Dernière année'},
      ],
      editId: null,
      mass: null,
      quantity: null,
    }
  },
  computed: {
    harvests() {
      let results = []
      let today = new Date()
      let lastOkDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - this.daysPast)
      let harvestDate = new Date()
      if (this.onlyVGPlantId && this.vGarden.vgarden_plants.some(vgplant => vgplant.id === this.onlyVGPlantId)) {
        for (const harvest of this.vGarden.vgarden_plants.find(vgplant => vgplant.id === this.onlyVGPlantId).harvests) {
          harvestDate = new Date(harvest.date.substr(0, 4), harvest.date.substr(5, 2) - 1, harvest.date.substr(8, 2))
          if (harvestDate > lastOkDate) {
            results.push(harvest)
          }
        }
      } else {
        for (const vgplant of this.vGarden.vgarden_plants.filter(vgplant => vgplant.garden_plant.plant.id === this.plantId)) {
          for (const harvest of vgplant.harvests) {
            harvestDate = new Date(harvest.date.substr(0, 4), harvest.date.substr(5, 2) - 1, harvest.date.substr(8, 2))
            if (harvestDate > lastOkDate) {
              results.push(harvest)
            }
          }
        }
      }

      let totalMass = 0
      let totalNumber = 0
      for (const harvest of results) {
        totalMass += harvest.mass
        totalNumber += harvest.number
      }

      return {results: results, totalMass: totalMass, totalNumber: totalNumber}
    }
  },
  methods: {
    dateParser(harvestDate) {
      return harvestDate.substr(8, 2) + "/" + (harvestDate.substr(5, 2)) + "/" + harvestDate.substr(0, 4)
    },
    qualityParser(qualityNumber) {
      if (qualityNumber == null) {
        return ""
      }
      if (qualityNumber <= 1) {
        return "FrownIcon"
      } else if (qualityNumber <= 4) {
        return "FrownIcon"
      } else if (qualityNumber <= 6) {
        return "MehIcon"
      } else if (qualityNumber <= 8) {
        return "SmileIcon"
      } else if (qualityNumber > 8) {
        return "SmileIcon"
      } else {
        return "MehIcon"
      }
    },
    editHarvest(id) {
      let harvest = this.harvests.results.find(e =>
          e.id === id
      )
      this.mass = harvest.mass ? harvest.mass : 0
      this.quantity = harvest.number ? harvest.number : 0
      if (this.editId === id) {
        this.editId = null
        this.mass = null
        this.quantity = null
      } else {
        this.editId = id
      }
    },
    updateHarvest() {
      let harvestToUpdate = this.harvests.results.find(e =>
          e.id === this.editId
      )
      harvestToUpdate.mass = this.mass
      harvestToUpdate.number = this.quantity
      let payload = harvestToUpdate
      payload.index = payload.id
      payload.vegetable_garden = this.vGarden.id
      this.$store.dispatch("garden/updateHarvest", payload)
          .then(() => {
            this.editId = null
            this.mass = null
            this.quantity = null
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Récolte mise à jour",
              text: "La mise à jour de votre récolte a bien été prise en compte"
            })
          })
    },
    openPlantHistoryPopUp() {
      this.plantHistoryPopUp = true
    }
  }
}
</script>

<style lang="scss">
.vgarden-list {
  width: 100%;
  overflow-y: auto;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
</style>
