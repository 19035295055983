<template>
  <div>
    <onBoardingPopOver targetId="searchOnboarding" :step="1"
                       zoomValue="1"
                       moduleInfo="l'encyclopédie"
                       title="Recherchez parmi des milliers de plantes"
                       text="Utilisez la barre de recherche pour trouver
                         les plantes qui vous intéressent, via leur nom scientifique ou commun."
                       moduleName="encyclopedia"/>
    <onBoardingPopOver targetId="searchFilterParent" :step="2" title="Affinez votre recherche"
                       :zoomValue="$store.state.app.windowWidth >= 768 ? '1' : '15'"
                       moduleInfo="l'encyclopédie"
                       text="Utilisez ces filtres pour trouver exactement les plantes que vous souhaitez : amie des oiseaux ou des insectes ? arbre ou plante à fleurs ? exposition, besoin en matière organique ... et plus encore !"
                       moduleName="encyclopedia"/>
    <onBoardingPopOver targetId="fourthPlant" :step="3" title="Apprenez en plus sur cette plante"
                       zoomValue="5"
                       moduleInfo="l'encyclopédie"
                       text="Cliquez sur une plante pour ouvrir sa fiche et découvrir ses caractéristiques ! Vous avez cette plante dans votre jardin ? Cliquez sur le bouton 'Ajouter au jardin', elle apparaitra ensuite dans votre liste de plantes et vous recevrez également des rappels d'entretien !"
                       moduleName="encyclopedia"/>
    <onBoardingPopOver targetId="faunaTab___BV_tab_button__" :step="4" title="Découvrez la faune des jardins"
                       text="Découvrez également la faune des jardins ! Vous avez vu un de ces animaux ? Cliquez sur sa fiche, puis déclarez-le avec le bouton 'Je l'ai vu' ! Vous retrouverez vos observations dans le menu en haut à gauche !"
                       zoomValue="5"
                       moduleInfo="l'encyclopédie"
                       moduleName="encyclopedia"/>
  </div>
</template>

<script>

import onBoardingPopOver from "@/components/onboarding/onBoardingPopOver.vue";

export default {
  name: 'EncyclopediaOnboarding',
  components: {
    onBoardingPopOver
  },
  data() {
    return {
      previousStartedOnBoarding: null
    }
  },
  mounted() {
    this.previousStartedOnBoarding = this.$pauseDifferentOnBoarding("encyclopedia")
    if (!this.$store.state.account.onBoarding.encyclopedia) {
      this.$store.dispatch("account/startOnBoarding", "encyclopedia")
    }

  },
  beforeDestroy() {
    if (!this.$store.state.account.onBoarding.encyclopedia) {
      this.$store.commit("account/PAUSE_ONBOARDING", "encyclopedia")
    }
    for (const previousOnboarding of this.previousStartedOnBoarding) {
      this.$store.dispatch("account/startOnBoarding", previousOnboarding)
    }
  }
}
</script>
