<template>
  <b-popover @hide="manageHide" @show="addShine" :placement="placement" variant="primary" :show="$store.state.account.onBoardingState[this.moduleName].currentState===step" :target="targetId" :title="title" v-if="$store.state.account.onBoardingState[this.moduleName].currentState===step">
    <p class="text-center"><span><feather-icon
        icon="TargetIcon"
        class="text-secondary mr-50"
        size="20"/></span>{{ text }}</p>
    <p class="text-center"><i> Pour passer l'explication de <b class="text-primary">{{moduleInfo}}</b> et commencer à utiliser Hortilio, cliquez sur 'Arrêter' </i></p>
    <div class="d-flex justify-content-around">
    <b-button @click="stopOnBoarding"> Arrêter </b-button>
    <b-button variant="primary" @click="$store.dispatch('account/incrementOnBoarding', moduleName)"> Suivant </b-button>
    </div>
  </b-popover>
</template>

<script>
import {BButton,  BPopover} from 'bootstrap-vue'

export default {
  name: 'OnBoardingPopOver',
  components: {
    BButton,
    BPopover,
  },
  props: {
    targetId: String,
    title: String,
    text: String,
    step: Number,
    moduleName: String,
    moduleInfo: String,
    zoomValue: {
      type: String,
      default: '5'
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  methods: {
    addShine() {
      const targetElt = document.getElementById(this.targetId)
      if (targetElt) {
        for (const className of this.getTargetClassName()) {
          targetElt.classList.add(className)
        }
      }
    },
    manageHide(event) {
      event.preventDefault()
    },
    getTargetClassName() {
      return ['shiny-div', 'zoom-onboarding-' + this.zoomValue]
    },
    stopOnBoarding() {
      let payload = {id: this.$store.state.account.onBoarding.id}
      payload[this.moduleName] = true
      this.$store.dispatch("account/updateOnBoarding", payload)
      this.$store.commit("account/STOP_ONBOARDING", this.moduleName)
      const targetElt = document.getElementById(this.targetId)
      if (targetElt) {
        for (const className of this.getTargetClassName()) {
          targetElt.classList.remove(className)
        }
      }
    }
  },
  watch: {
    '$store.state.account.onBoardingState.encyclopedia.currentState'(val) {
      if (val === this.step+1) {
        const targetElt = document.getElementById(this.targetId)
        if (targetElt) {
          for (const className of this.getTargetClassName()) {
            targetElt.classList.remove(className)
          }
        }
      }
    },
    '$store.state.account.onBoardingState.home.currentState'(val) {
      if (val === this.step+1) {
        const targetElt = document.getElementById(this.targetId)
        if (targetElt) {
          for (const className of this.getTargetClassName()) {
            targetElt.classList.remove(className)
          }
        }
      }
    }
  }
}
</script>
