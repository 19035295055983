<template>
  <div class="plant-tasks">
    <div class="d-flex flex-wrap justify-content-around w-100">
      <TaskObject
          v-for="(type, index) of types"
          :key="index"
          :inSeason="type.inSeason"
          :name="vGPlant ? vGPlant.garden_plant.plant.common_name : null"
          :onlyVGPlantId="specificPlant ? vGardenPlantId : null"
          :plantId="plantId"
          :text="taskText(type.type)"
          :state="currentState"
          :vGarden="vGarden"
          @harvest="addHarvest"
          @taskValidated="taskValidated">

        <img slot="image" :alt="'Icon ' + type.type" :src="getImage(type.type)" style="height: 2.5rem !important;" class="mr-1">
      </TaskObject>

      <div class="  m-50 rounded-lg bg-secondary-light">
        <h5 class="mb-50 text-center"> <strong>Cette plante est
          {{getState(currentState.state)}} ! </strong> <br> Un changement ? Indiquez-le : </h5>

        <div class="d-flex justify-content-center flew-wrap mt-50">
          <b-button
              v-if="currentState.state==='PA' || currentState.state==='SO' ||  currentState.state==='AC' "
              class="mr-25 p-1"
              variant="outline-info"
              @click="removedPrompt = true">
            {{ plantOnly ? "Retirée" : "Retirées" }}
          </b-button>

          <b-button
              v-if="currentState.state==='PL' || currentState.state==='DE'"
              class="mr-25 p-1"
              variant="outline-info"
              @click="unplantedPrompt = true">
            {{ plantOnly ? "Arrachée" : "Arrachées" }}
          </b-button>

          <b-button
              v-if="currentState.state === 'SO' || currentState.state === 'AC' || currentState.state==='PL'"
              class="mr-25 p-1"
              variant="outline-warning"
              @click="sickPrompt = true">
            {{ plantOnly ? "Malade" : "Malades" }}
          </b-button>

          <b-button
              v-if="currentState.state==='SO' || currentState.state==='AC' ||  currentState.state==='PL' "
              class="p-1"
              variant="outline-danger"
              @click="deadPrompt = true">
            {{ plantOnly ? "Morte" : "Mortes" }}
          </b-button>
        </div>
      </div>

      <b-form-group class="text-center m-50" v-if="specificPlant">
        <label class="font-weight-bold font-medium-1 mb-50">Agir sur : </label>
        <b-form-radio-group v-model="plantOnly">
          <b-form-radio :value="true">Cette plante uniquement</b-form-radio>
          <b-form-radio :value="false">Toutes les plantes {{vGPlant ? vGPlant.garden_plant.plant.common_name : null}},
            dans l'état "{{getState(currentState.state)}}"</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>

    <b-modal
        ref="removedPrompt"
        v-model="removedPrompt"
        :title="plantOnly ? 'Retirer une plante' : 'Retirer des plantes'"
        cancel-title="Annuler"
        centered
        ok-title="Retiré"
        @ok="setRemoved('RE')">
      <div class="flex-col align-items-center">
        <b-form-group :label="'Quand avez-vous retiré ' + (plantOnly ? 'cette plante ?' : 'ces plantes ?')"
                      :description="'Etes-vous sûr(e) d\'avoir retiré ' + (specificPlant ? 'cette plante ?' : 'toutes ces plantes ?') ">
          <flat-pickr v-model="dateDone" :config="configStateDateTimePicker" class="form-control mb-1"/>
        </b-form-group>
      </div>
    </b-modal>

    <b-modal
        ref="unplantedPrompt"
        v-model="unplantedPrompt"
        :title="plantOnly ? 'Arracher une plante' : 'Arracher des plantes'"
        cancel-title="Annuler"
        centered
        ok-title="Arracher"
        @ok="setRemoved('UP')">
      <div class="flex-col align-items-center">
        <b-form-group :label="'Quand avez-vous arraché ' + (plantOnly ? 'cette plante ?' : 'ces plantes ?')"
                      :description="'Etes-vous sûr(e) d\'avoir arraché ' + (specificPlant ? 'cette plante ?' : 'toutes ces plantes ?') ">
          <flat-pickr v-model="dateDone" :config="configStateDateTimePicker" class="form-control mb-1"/>
        </b-form-group>
      </div>
    </b-modal>

    <b-modal
        ref="deadPrompt"
        v-model="deadPrompt"
        :title="plantOnly ? 'Indiquer une plante morte' : 'Indiquer des plantes mortes'"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        @ok="setRemoved('DE')">

      <b-form-group
          :description="'Etes-vous sûr(e) de vouloir déclarer ' + (plantOnly ? 'cette plante comme morte ?' : 'ces plantes comme mortes ?')"
          :label="'Quand ' + (plantOnly ? 'cette plante est-elle morte?' : 'ces plantes sont-elles mortes ?')">
        <flat-pickr v-model="dateDone" :config="configStateDateTimePicker" class="form-control mb-1"/>
      </b-form-group>
    </b-modal>

    <b-modal
        ref="sickPrompt"
        v-model="sickPrompt"
        :title="plantOnly ? 'Déclarer une plante malade' : 'Déclarer des plantes malades'"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        @ok="createIllness">

      <b-form-group
          :description="'Etes-vous sûr(e) de vouloir déclarer ' + (plantOnly ? 'cette plante comme malade ?' : 'ces plantes comme malades ?')"
          :label="'Quand ' + (plantOnly ? 'cette plante est-elle tombée malade?' : 'ces plantes sont-elles tombées malades ?')">
        <flat-pickr v-model="dateDone" :config="configIllnessDateTimePicker" class="form-control mb-1"/>
        <label>Décrivez la maladie si vous le souhaitez : </label>
        <b-form-textarea v-model="sicknessDetails"/>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import TaskObject from '@/components/vgarden/tasks/VGardenTaskObject.vue'
import FlatPickr from 'vue-flatpickr-component'
import {BButton, BFormGroup, BFormTextarea, BModal, BFormRadioGroup, BFormRadio} from "bootstrap-vue";

export default {
  name: 'PlantTasks',
  components: {
    BModal,
    BButton,
    BFormTextarea,
    TaskObject,
    FlatPickr,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio
  },
  props: {
    plantId: {
      type: Number,
      default: -1
    },
    vGardenPlantId: {
      type: Number,
      default: -1
    },
    specificPlant: {
      type: Boolean,
      defalut: false
    }
  },
  data() {
    return {
      activeTaskDateDone: null,
      stateParser: {'SO': 'transplant', 'PA': 'sow', 'AC': 'transplant', 'PL': 'harvest'},
      plantOnly: true,
      removedPrompt: false,
      unplantedPrompt: false,
      deadPrompt: false,
      sickPrompt: false,
      configStateDateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        defaultDate: new Date(),
        minDate: new Date(),
        maxDate: new Date()
      },
      configIllnessDateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        defaultDate: new Date(),
        minDate: new Date(),
        maxDate: new Date()
      },
      dateDone: null,
      sicknessDetails: ""
    }
  },
  mounted() {
    this.configStateDateTimePicker.minDate = new Date(this.currentState.state === 'PA' ?
        null : this.currentState.date)
    this.configIllnessDateTimePicker.minDate = new Date(this.vGPlant.in_garden_date)
  },
  computed: {
    vGarden() {
      return this.$store.state.garden.vgardens.find(vGarden => vGarden.vgarden_plants.some(plant => plant.id === this.vGardenPlantId))
    },
    vGPlant() {
      if (this.vGarden && this.vGarden.vgarden_plants.some(plant => plant.id === this.vGardenPlantId)) {
        return this.vGarden.vgarden_plants.find(plant => plant.id === this.vGardenPlantId)
      }
      return null
    },
    plantTasks() {
      let vGarden = this.vGarden
      if (vGarden) {
        if (this.vGarden.tasks && this.vGarden.tasks.some(task => {
          return task.object === 'plant' && task.plantType.id === this.plantId && task.tasks[this.stateParser[this.vGPlant.current_state.state]]
        })) {
          return this.vGarden.tasks.find(task => task.object === 'plant' && task.plantType.id === this.plantId && task.tasks[this.stateParser[this.vGPlant.current_state.state]]).tasks
        }
      }
      return []
    },
    types() {
      let resultArray = []
      if (this.plantTasks) {
        if (this.plantTasks.water) {
          resultArray.push({type: "water", inSeason: true})
        }
        if (this.plantTasks.sow) {
          resultArray.push({type: "sow", inSeason: true})
        } else if (this.stateParser[this.vGPlant.current_state.state] === "sow") {
          resultArray.push({type: "sow", inSeason: false})
        }
        if (this.plantTasks.transplant) {
          resultArray.push({type: "transplant", inSeason: true})
        } else if (this.stateParser[this.vGPlant.current_state.state] === "transplant") {
          resultArray.push({type: "transplant", inSeason: false})
        }
        if (this.plantTasks.trim) {
          resultArray.push({type: "trim", inSeason: true})
        }
        if (this.plantTasks.harvest) {
          resultArray.push({type: "harvest", inSeason: true})
        } else if (this.stateParser[this.vGPlant.current_state.state] === "harvest") {
          resultArray.push({type: "harvest", inSeason: false})
        }
        resultArray.push()
      }
      return resultArray
    },
    plantState() {
      let plant = this.vGarden.vgarden_plants.find(vGPlant => vGPlant.id === this.vGardenPlantId)
      return plant ? plant.current_state.state : null
    },
    currentState() {
      return this.vGarden.vgarden_plants.find(plant => plant.id === this.vGardenPlantId).current_state
    }
  },
  methods: {
    taskText(type) {
      if (type === 'water') {
        return 'J\'ai arrosé'
      } else if (type === 'sow') {
        return 'J\'ai semé'
      } else if (type === 'transplant') {
        return 'J\'ai replanté'
      } else if (type === 'trim') {
        return 'J\'ai taillé'
      } else if (type === 'harvest') {
        return 'J\'ai recolté'
      } else {
        return null
      }
    },
    getState(st) {
      return this.$store.state.garden.vgardenPlantStates.find(state => state.value === st).text
    },
    getImage(image) {
      let folderLocation = ''
      switch (image) {
        case "water":
          folderLocation = 'tools/watering_can'
          break;
        case "sow":
          folderLocation = 'other/sowing_hand'
          break;
        case "transplant":
          folderLocation = 'tools/garden_trowel'
          break;
        case "trim":
          folderLocation = 'tools/shear'
          break;
        case "harvest":
          folderLocation = 'tools/basket'
          break;
        default:
          folderLocation = 'tools/shovel'
      }
      let img = ""
      try {
        img = require("@/assets/images/icons/" + folderLocation + ".svg")
      } finally {
        // do nothing
      }
      return img
    },
    addWatered(state) {
      let payload = {
        vegetable_garden: this.vGarden,
        index_store: this.vGarden.vgarden_plants.findIndex(vGPlant => vGPlant.id === this.vGardenPlantId),
        state: state
      }
      this.$store.dispatch("garden/createWatering", payload)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "Primary",
              title: "Arrosage",
              text: "Arrosage bien pris en compte"
            })
          }).catch(() => {
        this.$notify({
          time: 4000,
          color: "danger",
          title: "Erreur",
          text: "Une erreur est survenue lors de la prise en compte de l'arrosage, veuillez réessayer plus tard"
        })
      })
    },
    getDateJSON(eventDate) {
      let date =  eventDate ? new Date(eventDate) : new Date(new Date().setUTCHours(0,0,0,0))
      return date.toISOString()
    },
    taskValidated(e) {
      if (this.specificPlant && this.plantOnly) {
        let payload = {
          state: e.info,
          v_garden_plant: this.vGardenPlantId,
          date: this.getDateJSON(e.date)
        }
        this.$store.dispatch('garden/createVGPlantState', payload)
            .then(() => {
              this.$notify({
                time: 4000,
                title: 'Plante mise à jour',
                text: 'Votre plante a bien été mise à jour',
                color: "primary"
              })
              if (payload.state === "UP" || payload.state === "DE" || payload.state === "RE") {
                this.$emit("removed")
              } else {
                this.$emit('task-validated')
              }
            })
      } else {
        let plants = this.vGarden.vgarden_plants.filter(pl => {
          return pl.garden_plant.plant.id === e.plantId && pl.current_state.state === e.type
        })
        let callsToDo = plants.length
        let callsDone = 0
        for (let plant of plants) {
          let payload = {
            state: e.info,
            v_garden_plant: plant.id,
            date: this.getDateJSON(e.date)
          }
          this.$store.dispatch('garden/createVGPlantState', payload)
              .then(() => {
                callsDone += 1
                if (callsDone === callsToDo) {
                  if (payload.state === "UP" || payload.state === "DE" || payload.state === "RE") {
                    this.$emit("removed")
                  } else {
                    this.$emit('task-validated')
                  }
                  this.$notify({
                    time: 4000,
                    title: 'Plante(s) mise(s) à jour',
                    text: 'Vos plante(s) ont bien été mise(s) à jour',
                    color: "primary"
                  })
                }
              })
        }
      }
    },
    addHarvest(e) {
      //if harvest use feedback info
      if (e.info === 'HA' && e.feedBack && e.feedBack.type) {
        let plants = []
        if (this.specificPlant && this.plantOnly) {
          plants = [this.vGarden.vgarden_plants.find(vgplant => vgplant.id === this.vGardenPlantId)]
        } else {
          plants = this.vGarden.vgarden_plants.filter(pl => {
            return pl.garden_plant.plant.id === e.plantId && pl.current_state.state === e.type && e.feedBack.plantResults.quantity[pl.id]
          })
        }
        for (let plant of plants) {
          let payload = {
            vegetable_garden: this.vGarden.id,
            v_garden_plant: plant.id,
            index_store: this.vGarden.vgarden_plants.findIndex(vGPlant => vGPlant.id === plant.id),
            state: e.state,
            date: this.getDateJSON(e.date)
          }
          switch (e.feedBack.plantResults.quality[plant.id]) {
            case -2:
              payload.quality = 1
              break;
            case -1:
              payload.quality = 3
              break;
            case 0:
              payload.quality = 5
              break;
            case 1:
              payload.quality = 7
              break;
            case 2:
              payload.quality = 10
              break;
            default:
              payload.quality = null
              break;
          }

          if (e.feedBack.type === 'quantity') {
            payload.number = e.feedBack.plantResults.quantity[plant.id]
          } else {
            payload.mass = e.feedBack.plantResults.quantity[plant.id]
          }
          this.$store.dispatch("garden/createHarvest", payload)
              .then(() => {
                this.$notify({
                  time: 4000,
                  color: "primary",
                  title: "Récolte",
                  text: "Récolte bien prise en compte"
                })
              })
              .catch(() => {
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Erreur",
                  text: "Une erreur est survenue lors de la prise en compte de la récolte, veuillez réessayer plus tard"
                })
              })
        }
      } else {
        this.$notify({
          time: 4000,
          color: "danger",
          title: "Erreur",
          text: "Une erreur est survenue lors de la prise en compte de la récolte, veuillez réessayer plus tard"
        })
      }
    },
    setRemoved(state) {
      let values = {
        date: this.dateDone,
        info: state,
      }
      if (!this.plantOnly || !this.specificPlant) {
        values.type = this.currentState.state
        values.plantId = this.plantId
      }
      this.taskValidated(values)
      this.removedPrompt = false
      this.unplantedPrompt = false
    },
    createIllness() {
      let plants = []
      if (this.specificPlant && this.plantOnly) {
        plants = [this.vGarden.vgarden_plants.find(vgplant => vgplant.id === this.vGardenPlantId)]
      } else {
        plants = this.vGarden.vgarden_plants.filter(pl => {
          return pl.garden_plant.plant.id === this.plantId && pl.current_state.state === this.currentState.state
        })
      }
      let payload = {
        start_date: this.getDateJSON(this.dateDone),
        comment: this.sicknessDetails
      }
      let nbCalls = 0
      for (let plant of plants) {
        payload.v_garden_plant = plant.id
        this.$store.dispatch("garden/createIllness", payload)
            .then(() => {
              if (nbCalls === plants.length) {
                this.$notify(
                    {
                      time: 4000,
                      title: "Maladie(s)",
                      text: "Maladie(s) prise(s) en compte",
                      color: "primary"
                    }
                )
              }
              nbCalls += 1
            })
      }
    },
  }
}
</script>
